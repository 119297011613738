import axios from 'axios'

import * as utils from './utils'

axios.defaults.withCredentials = true

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8001/api' : '/api'

export function apiCheckAuth() {
  return axios.get(`${apiUrl}/common/checkAuth`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiGetMe() {
  return axios.post(`${apiUrl}/user/me/get`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiSetMyLanguage(language) {
  return axios.post(`${apiUrl}/user/me/language/set`, {language})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiLogin(token) {
  return axios.post(`${apiUrl}/common/login`, {token})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiLogout() {
  return axios.post(`${apiUrl}/common/logout`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiGetChannels(user_id) {
  return axios.post(`${apiUrl}/channels/get`, {user_id})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiCreatePost(post) {
  return axios.post(`${apiUrl}/post/create`, {post})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiPreviewPost(post) {
  return axios.post(`${apiUrl}/post/preview`, {post})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiEditPost(id, post) {
  return axios.post(`${apiUrl}/post/${id}/edit`, {post})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiDeletePost(id) {
  return axios.post(`${apiUrl}/post/${id}/delete`, {id})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiUploadMedia(data, type) {
  if (type === 'file') {
    const formData = new FormData()
    formData.append('image', data)
    return axios.post(
      `${apiUrl}/media/upload?type=file`,
      formData,
      {headers: {'Content-Type': 'multipart/form-data'}},
    )
      .then(utils.handleAxiosSuccess)
      .catch(utils.handleAxiosError)
  } else if (type === 'url') {
    return axios.post(
      `${apiUrl}/media/upload`,
      {
        type: 'url',
        url: data,
      },
    )
      .then(utils.handleAxiosSuccess)
      .catch(utils.handleAxiosError)
  }
}

export const apiGetPostsByCid = ({cid, limit = 2000, offset = 0}) => {
  return axios.post(`${apiUrl}/channel/${cid}/posts/get`, {
    limit,
    offset,
  })
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export const apiSetLanguage = ({cid, value}) => {
  return axios.post(`${apiUrl}/channel/${cid}/language/set`, {
    value,
  })
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export const apiSetRequireSubscription = ({cid, value}) => {
  return axios.post(`${apiUrl}/channel/${cid}/requireSubscription/set`, {
    value,
  })
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export const apiSetRequireSubscriptionAlertText = ({cid, value}) => {
  return axios.post(`${apiUrl}/channel/${cid}/requireSubscriptionAlertText/set`, {
    value,
  })
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export const apiSetRequireSubscriptionShowAlert = ({cid, value}) => {
  return axios.post(`${apiUrl}/channel/${cid}/requireSubscriptionShowAlert/set`, {
    value,
  })
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export const apiUpdateAdmins = ({cid}) => {
  return axios.post(`${apiUrl}/channel/${cid}/admins/update`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiOpenPost(id) {
  return axios.post(`${apiUrl}/post/${id}/open`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiClosePost(id) {
  return axios.post(`${apiUrl}/post/${id}/close`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiGetPost(id) {
  return axios.post(`${apiUrl}/post/${id}/get`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiGetStats(id) {
  return axios.post(`${apiUrl}/post/${id}/stats/get`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiGetBasicStats(id) {
  return axios.post(`${apiUrl}/post/${id}/basicStats/get`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiReportError(data) {
  return axios.post(`${apiUrl}/misc/reportError`, {data})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiAdminGetChannels() {
  return axios.post(`${apiUrl}/admin/channels/get`)
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}

export function apiAdminSetPaidUntil(cid, value) {
  return axios.post(`${apiUrl}/admin/channel/${cid}/paid_until/set`, {value})
    .then(utils.handleAxiosSuccess)
    .catch(utils.handleAxiosError)
}
