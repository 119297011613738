<template>
  <div class="snackbar" :class="{'snackbar_expanded': data.expanded}">
    <div class="snackbar__description-container">
      <i v-if="data.type === 'error'" class="material-icons snackbar__sign snackbar__sign_error">error_outline</i>
      <i v-if="data.type === 'success'" class="material-icons snackbar__sign snackbar__sign_success">check_circle_outline</i>
      <span class="snackbar__description" v-html="data.message"/>

    </div>

    <div class="spacer"/>

    <div class="snackbar__buttons">
      <template v-for="(action, i) in data.actions">
        <router-link
          v-if="action.type === 'router_link'"
          :key="i"
          class="button snackbar__button"
          @click="data.closeOnAction && $emit('close')"
          :to="action.to">
          {{action.description}}
        </router-link>
        <button
          v-else
          :key="i"
          class="button snackbar__button"
          @click="(data.closeOnAction && $emit('close') &&0) || action.action()">
          {{action.description}}
        </button>
      </template>
      <button
        class="snackbar__close-button material-icons"
        @click="$emit('close')">close
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Snackbar',
    props: {
      data: Object,
    },
    methods: {
      close() {
        this.$emit('close')
      },
    },
    mounted() {
      if (Number.isFinite(this.data.timeToClose)) {
        setTimeout(() => {
          this.close()
        }, this.data.timeToClose)
      }
    },
  }
</script>

<style lang="stylus" scoped>
  .snackbar
    @apply w-full rounded bg-gray-800 z-10
    @apply flex flex-row items-center
    @apply shadow-md

    @apply mt-2

    width 100%

  .snackbar__description-container
    flex-grow 1
    line-height 1.25rem
    @apply text-gray-100
    padding 14px 16px
    letter-spacing .0178571429em

    @apply self-stretch
    @apply flex flex-row items-start

  .snackbar__description
    @apply my-auto

  .snackbar__buttons
    @apply flex flex-row items-center
    padding-right 8px


  .snackbar.snackbar_expanded
    @apply flex-col

    .snackbar__description-container
      @apply pb-0

    .snackbar__buttons
      padding 0 8px 8px 0
      @apply self-end


  .snackbar__button
    @apply font-semibold
    color #bb86fc
    letter-spacing .0178571429em

    &:hover
      color #bb86fc
      background-color rgba(98, 0, 238, .12)

  .snackbar__close-button
    @apply text-gray-300

    font-size 18px

    width 35px
    height 35px
    padding 8px

    border-radius 17.5px

    &:hover
      @apply bg-gray-700

  .snackbar__sign
    cursor default
    vertical-align middle
    @apply pr-2

  .snackbar__sign_error
    @apply text-red-500

  .snackbar__sign_success
    @apply text-green-500
</style>

<style lang="stylus">
  .snackbar__code
    font-family monospace
    padding 0 !important
    background-color transparent !important
    color inherit !important
</style>
