import SnackbarContainer, { snackbar } from './SnackbarContainer.vue'

export default {
  install(Vue) {
    Vue.prototype.$snackbar = {
      show(opts) {
        snackbar.show(opts)
      },
      hide() {
        snackbar.hide()
      }
    }

    Vue.component(SnackbarContainer.name, SnackbarContainer)
  }
}

export {snackbar}