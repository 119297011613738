<template>
  <transition-group
    :duration="500"
    name="snackbar__fade"
    class="snackbar-container"
    tag="div">
    <snackbar
      v-for="snackbar in snackbars"
      :data="snackbar"
      :key="snackbar.id"
      @close="hideSnackbarById(snackbar.id)"/>
  </transition-group>
</template>

<script>
  import { Bus } from 'ubus'

  const ubus = new Bus()
  const TIME_TO_HIDE = 30000
  const busEvents = {
    SHOW: 'snackbar.show',
    CLOSE: 'snackbar.hide',
    HIDE_ALL: 'snackbar.hide_all',
  }

  export const snackbar = {
    show(cfg) {
      if (!cfg) {
        return
      }

      cfg = {
        timeToHide: TIME_TO_HIDE,
        type: 'default',
        expanded: false,
        ...cfg,
      }

      ubus.emit(busEvents.SHOW, cfg)
    },
    hide() {
      ubus.emit(busEvents.CLOSE)
    },
  }

  import Snackbar from './Snackbar.vue'

  export default {
    name: 'SnackbarContainer',
    components: {
      Snackbar,
    },
    data: () => ({
      snackbars: [],
      snackbarId: 0,
    }),
    methods: {
      hide() {
        clearTimeout(this.idTimeoutSnackbar)
        this.cfgSnackbar.visible = false
      },
      hideSnackbarByIndex(index) {
        this.snackbars.splice(index, 1)
      },
      hideSnackbarById(id) {
        return this.hideSnackbarByIndex(this.snackbars.findIndex(s => s.id === id))
      },
      hideAll() {
        this.snackbars = []
      }
    },
    mounted() {
      ubus.on(busEvents.SHOW, (cfg) => {
        this.snackbars.push({
          ...cfg,
          id: this.snackbarId,
        })
        this.snackbarId++
      })

      ubus.on(busEvents.CLOSE, () => this.hideSnackbarByIndex(0))
      ubus.on(busEvents.HIDE_ALL, () => this.hideAll())
    },
  }

  //todo переписать на абсолютах, а то анимашки дергаются, когда сразу несколько добавляются/удаляются
</script>

<style lang="stylus" scoped>
  .snackbar-container
    @apply fixed
    bottom 15px
    left 15px

    width calc(100% - 30px)

    transition 1s

    @media (min-width: 600px)
      max-width 400px
      /*max-width max-content*/
    @apply flex flex-col

  .snackbar__fade-move
    transition transform 500ms

  .snackbar__fade-enter-active
    transition 500ms

  .snackbar__fade-leave-active
    transition all 10000000000s, opacity 500ms
    position absolute
    z-index -1

  .snackbar__fade-enter
    /*opacity 0*/
    transform translateY(100px)

  .snackbar__fade-leave-to
    opacity 0
</style>
