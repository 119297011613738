export const handleAxiosSuccess = res => res.data
export const handleAxiosError = error => error.response
  ? error.response.data
  : {
    ok: false,
    error: {
      code: 503,
      name: "NETWORK_ERROR"
    }
  }
export const getDefaultButton = () => ({
  ...{
    type: 'answer',
    text: '',
  }
})

export const chain = async (argument, next, functions) => {
  functions = functions.filter(f => typeof f === 'function')
  if (functions.length === 0) {
    return next()
  }

  let stack = []

  const handleDone = (r) => {
    if (!r.ok) {
      return next(r.next)
    }
    if (r.next && typeof r.next === 'function') {
      stack.push(r.next)
    }
    if (i >= functions.length) {
      return next((vm) => {
        stack.forEach(f => f.call(null, vm))
      })
    }
    return run()
  }

  let i = 0
  const run = () => Promise.resolve(functions[i++].call(null, argument)).then(handleDone)


  return run()
}

export const getChannelLabel = (channel) => channel.name
