import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: () => import('./views/Landing.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue'),
    },
    {
      path: '*',
      component: () => import('@/views/Layout/FixedHeaderLayout.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/Login.vue'),
        },
        {
          path: '/new',
          name: 'new',
          component: () => import('./views/Newpost.vue')
        },
        {
          path: '/edit/:id',
          name: 'edit',
          component: () => import('./views/PostEdit.vue'),
        },
        {
          path: '/stats/:id',
          component: () => import('./views/Stats.vue'),
        },
        {
          path: '/dashboard',
          component: () => import('./views/Dashboard.vue'),
        },
        {
          path: '/channel/:id',
          component: () => import('./views/Channel.vue'),
          children: [
            {
              path: 'posts',
              name: 'channel_posts',
              component: () => import('@/components/Channel/ChannelPosts.vue'),
            },
            // {
            //   path: 'templates',
            //   name: 'channel_templates',
            //   component: () => import('@/components/Channel/ChannelTemplates.vue'),
            // },
            {
              path: 'admins',
              name: 'channel_admins',
              component: () => import('@/components/Channel/ChannelAdmins.vue'),
            },
            {
              path: 'settings',
              name: 'channel_settings',
              component: () => import('@/components/Channel/ChannelSettings.vue'),
            },
            {
              path: '*',
              redirect: {name: 'channel_posts'},
            },
            {
              path: '',
              redirect: {name: 'channel_posts'},
            },
          ],
        },
        {
          path: '/post/:id',
          component: () => import('./views/PostView.vue'),
        },
        {
          path: '/admin',
          component: () => import('./views/AdminDashboard.vue'),
        },
        {
          path: '*',
          redirect: '/',
        },
      ]
    },
  ],
})
