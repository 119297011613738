import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.(?:json|yaml)$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    ru: function (choice) {
      if (choice === 0) {
        return 0
      }

      const last = choice % 10
      const last2 = choice % 100

      if (last === 1 && last2 !== 11) {
        return 1
      }

      if (last >= 2 && last <= 4 && !(last2 >= 12 && last2 <= 14)) {
        return 2
      }

      return 3
    }
  }
});
