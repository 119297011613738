import Vue from 'vue'

import App from './App.vue'

import router from './router'
import store from './store/'
import i18n from './i18n'
import './registerServiceWorker'
import './plugins/element'
import './plugins/axios'
import './plugins/ls'
import './plugins/progressBar'
import './plugins/compositionApi'
import './plugins/snackbar'
import './plugins/smoothScroll'
// import './plugins/matomo'

import 'focus-visible/dist/focus-visible.min.js'

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
