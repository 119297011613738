import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    userId: -1,
    userIdFetched: false,
    user: {},
    userFetched: false,
    userChannels: [],
    userChannelsFetched: false,
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId
      state.userIdFetched = !!userId
    },
    setUser(state, user) {
      state.user = user
      state.userFetched = !!user
    },
    setUserChannels(state, channels) {
      state.userChannels = channels
      state.userChannelsFetched = true
    },
    setChannel(state, channel) {
      Vue.set(
        state.userChannels,
        state.userChannels.findIndex(v => v.cid === channel.cid),
        channel
      )
    },

    setMyLanguage(state, language) {
      state.user.language = language
    }
  },
  actions: {
    logout(store) {
      store.commit('setUserId', false)
      store.commit('setUser', false)
    },
  },
}
