import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './modules/auth'

export default new Vuex.Store({
  state: {
    apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8001/api' : 'https://channelquiz.loskir.ru/api',
  },
  modules: {
    auth,
  },
})
