<template>
  <div id="app">
    <vue-progress-bar/>
    <!--    <Loader/>-->
    <transition name="fade-200" v-if="!$route.matched || $route.matched.length === 0">
      <Loader/>
    </transition>
    <transition name="route-change-fade" v-else>
      <router-view/>
    </transition>
    <SnackbarContainer/>
  </div>
</template>

<script>
  const Loader = () => import('@/components/Loader.vue')

  import {
    apiSetMyLanguage,
  } from '@/functions/api'

  export default {
    name: 'app',
    components: {
      Loader,
    },
    watch: {
      async '$i18n.locale'(v) {
        if (v === this.$store.state.auth.user.language) {
          return
        }
        const result = await apiSetMyLanguage(v)

        if (result.ok) {
          this.$store.commit('auth/setMyLanguage', v)
        }
      },
    },
    mounted() {
      // this.$Progress.finish()
      // setInterval(() => console.log(this.$route), 100)
    },
    created() {
      this.$Progress.start()

      this.$router.beforeEach((to, from, next) => {
        if (from.path === to.path) {
          // поменялась только query
          return next()
        }
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress
          this.$Progress.parseMeta(meta)
        }
        this.$Progress.start()
        next()
      })
      this.$router.afterEach(() => {
        this.$Progress.finish()
      })
    },
  }
</script>

<style lang="stylus">
  @import "~material-icons/iconfont/material-icons.css"
  @import '~@/components/Button/button.styl'
  @import '~@/components/Switch/switch.styl'

  *, *:before, *:after
    box-sizing border-box
    font-family inherit

  code
    font-family "Courier New", Consolas, monospace

  html
    font-size 14px

  body
    color: #212529
    font-family Roboto, Helvetica, Arial, sans-serif
    margin 0 auto

  #app
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

    display flex
    flex-direction column
    justify-content space-between

    min-height 100vh

  /* purgecss start ignore */
  @tailwind base;
  //@tailwind components;
  /* purgecss end ignore */

  @tailwind utilities;

  h2
    @apply text-3xl
    @apply font-bold mb-2

  h3
    @apply text-2xl
    @apply font-bold mb-2

  h4
    @apply text-xl
    @apply font-bold

  a
    @apply text-blue-600 no-underline

    &:hover
      @apply text-blue-700

    /*&:not(:hover)*/
      /*transition color .25s*/

  .input_hidden
    position absolute
    width 0
    height 0
    z-index -1
    opacity 0

  .icon_size_sm
    width 16px
    height 16px

  .spacer
    flex-grow 1

  .el-dropdown-link
    outline none

  a, button, .dropdown-link
    /*text-decoration none !important*/
    color #409EFF

    &:hover
      color #66b1ff

    &.hover-danger:hover
      color red

    /*&.hover-danger:not(:hover)*/
      /*transition color .25s*/

  button, a, .focusable
    &:focus
      outline 1px dotted -webkit-focus-ring-color

    &:not(:focus-visible)
      outline none

  .icon--dots
    margin auto
    height 100%

  .ce-settings.ce-settings--opened
    box-sizing content-box

  ._effect_shake
    animation _effect_shake .5s

  @keyframes _effect_shake
    0%, 100%
      transform translateX(0)
    20%
      transform translateX(-5px)
    40%
      transform translateX(7.5px)
    60%
      transform translateX(-10px)
    80%
      transform translateX(10px)

  .error-textarea
    font-family monospace
    height 300px
    width 100%
    resize none

  .el-message-box
    width 98% !important
    max-width 420px
</style>

<style lang="stylus">
  .fade-enter-active, .fade-leave-active
    transition opacity .5s

  .fade-enter, .fade-leave-to
    opacity 0

  .fade-300-enter-active, .fade-300-leave-active
    transition opacity 300ms

  .fade-300-enter, .fade-300-leave-to
    opacity 0

  .fade-200-enter-active, .fade-200-leave-active
    transition opacity 200ms

  .fade-200-enter, .fade-200-leave-to
    opacity 0

  .route-change-fade-enter-active, .route-change-fade-leave-active
    position absolute !important
    width 100%
    transition opacity .2s

  .route-change-fade-enter, .route-change-fade-leave-to
    opacity 0
</style>
